/*! themes/_main.scss | Bulkit | CSS Ninja */

/* ==========================================================================
Main theme variables and styles (Main theme is used in "Startup Kit", in "Landing kit v1" and in all components pages)
========================================================================== */

/***
TABLE OF CONTENTS
=============================================================================
0. Theme color variables
1. Theme gradient
2. Box shadows
3. Pageloader
4. Theme specific Hero
=============================================================================
***/

/* ==========================================================================
0. Theme color variables
========================================================================== */
$primary: #9123A7; //#4FC1EA;
$secondary: #7F00FF;
$accent: #9123A7;// #7F00FF;

/* ==========================================================================
1. Theme gradient
========================================================================== */
$webkit-hero-gradient: linear-gradient(to right, $secondary, $accent);
$hero-gradient: linear-gradient(to top, $secondary, $accent);
$herotop-gradient: linear-gradient(to top, $accent, $secondary);

/* ==========================================================================
2. Box shadows
========================================================================== */
//Base shadow
$base-shadow: rgba(0, 0, 0, 0.12);

//Primary box shadow
$primary-shadow-from: rgba(79, 193, 234, 0.42);
$primary-shadow-to: rgba(79, 193, 234, 0.2);
$primary-box-shadow:  0 14px 26px -12px $primary-shadow-from, 0 4px 23px 0px $base-shadow, 0 8px 10px -5px $primary-shadow-to !important;

//Secondary box shadow
$secondary-shadow-from: rgba(103, 58, 183, 0.42);
$secondary-shadow-to: rgba(103, 58, 183, 0.2);
$secondary-box-shadow:  0 14px 26px -12px $secondary-shadow-from, 0 4px 23px 0px $base-shadow, 0 8px 10px -5px $secondary-shadow-to !important;

//Accent box shadow
$accent-shadow-from: rgba(127, 0, 255, 0.42);
$accent-shadow-to: rgba(127, 0, 255, 0.2);
$accent-box-shadow:  0 14px 26px -12px $accent-shadow-from, 0 4px 23px 0px $base-shadow, 0 8px 10px -5px $accent-shadow-to !important;


/* ==========================================================================
3. Pageloader
========================================================================== */
.pageloader {
    background-color: $secondary;
    background: $herotop-gradient !important;
}

/* ==========================================================================
4. Theme specific Hero
========================================================================== */
.hero, .section {
    &.is-theme-secondary {
        background-color: $secondary;
        background: $webkit-hero-gradient;
        background: $hero-gradient;
        .title {
            color: $white;
        }
        .subtitle {
            color: $white;
        }
    }
}


